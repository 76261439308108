import { Container } from '@finn/atoms';
import { CTA } from '@finn/ui-components';
import { cn } from '@finn/ui-utils';
import { Box, makeStyles } from '@material-ui/core';
import trim from 'lodash/trim';
import React from 'react';
import { useIntl } from 'react-intl';

import RawHtml from '~/components/RawHtml';
import {
  GuideMetaData,
  TextblockAddProps,
  TextblockCosmicMetadata,
} from '~/types/UIModules';
import SocialSharing from '~/ui-modules/Hero/SocialSharing/SocialSharing';
import { parseToHtml } from '~/utils/html';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '5fr 7fr',
      gridGap: theme.spacing(3),
    },
  },
  title: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(0),
    },
  },
  buttonWrapper: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
    },
  },
  metaBox: {
    margin: theme.spacing(9, 0, 0),
    padding: theme.spacing(3, 0, 0),
    display: 'flex',
    flexWrap: 'wrap',
  },
  metaBoxItem: {
    flexBasis: '50%',
    padding: theme.spacing(0, 0, 1.5),
    [theme.breakpoints.up('md')]: {
      flexBasis: '25%',
      padding: 0,
    },
  },
  noticeTitle: {
    whiteSpace: 'pre-wrap',
    '&:before': {
      content: '"\\a\\a"',
    },
    '&:after': {
      content: '"\\a\\a"',
    },
  },
}));

interface IProps {
  data: TextblockCosmicMetadata;
  onCTAClick?: Function;
  addProps?: TextblockAddProps;
}

const hasRelevantMetaField = (guideMetaData: GuideMetaData) => {
  return trim(guideMetaData?.photos_by) || trim(guideMetaData?.written_by);
};

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const Textblock: React.FunctionComponent<IProps> = ({
  data,
  onCTAClick = () => {},
  addProps,
  children,
}) => {
  const classes = useStyles();
  const { title, description, cta, guide_meta_data } = data;
  const { noticeTitle, noticeDescription, trackDynamicSection, containerRef } =
    addProps || {};
  const guideMetaData = guide_meta_data?.metadata;
  const intl = useIntl();

  return (
    <Container>
      <Box className={classes.wrapper}>
        <h2 className="mobile-t2-semibold md:web-t2-semibold mb-6 md:mb-0">
          {parseToHtml(title)}
        </h2>
        <Box>
          <div className="body-14-regular">
            <RawHtml html={description} useGlobalStyles fullWidthMedia />
            <ConditionalWrapper
              condition={trackDynamicSection}
              wrapper={(wrapperChildren) => (
                <div ref={containerRef}>{wrapperChildren}</div>
              )}
            >
              {noticeTitle && (
                <>
                  <span
                    className={cn(classes.noticeTitle, 'global-t5-semibold')}
                  >
                    {noticeTitle}
                  </span>
                  <span>{noticeDescription}</span>
                </>
              )}
            </ConditionalWrapper>
          </div>
          {(cta?.metadata?.text || addProps?.ctaText) && (
            <Box className={classes.buttonWrapper}>
              <CTA
                data={cta.metadata}
                addProps={addProps}
                onCTAClick={onCTAClick}
              />
            </Box>
          )}
          {hasRelevantMetaField(guideMetaData) ? (
            <Box
              className={cn(
                classes.metaBox,
                'border-pearl border-0 border-t border-solid'
              )}
            >
              {guideMetaData.written_by ? (
                <Box className={classes.metaBoxItem}>
                  <h6 className="global-t6-semibold">
                    {intl.formatMessage({
                      id: 'general.guideWrittenBy',
                    })}
                  </h6>
                  <span className="body-14-regular">
                    {guideMetaData.written_by}
                  </span>
                </Box>
              ) : null}
              {guideMetaData.photos_by ? (
                <Box className={classes.metaBoxItem}>
                  <h6 className="global-t6-semibold">
                    {intl.formatMessage({
                      id: 'general.guidePhotosBy',
                    })}
                  </h6>
                  <span className="body-14-regular">
                    {guideMetaData.photos_by}
                  </span>
                </Box>
              ) : null}
              <Box className={classes.metaBoxItem}>
                <h6 className="global-t6-semibold">
                  {intl.formatMessage({
                    id: 'general.guideShare',
                  })}
                </h6>
                <SocialSharing scale={0.7} />
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
      {children}
    </Container>
  );
};

export default Textblock;
